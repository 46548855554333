import React, { useState, useMemo, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputTextComponent from '../inputForm/InputText';
import { Video } from '../../model/video-model';
import InputFile from '../inputForm/inputFile';
import { ObjectType } from '../../enum/ObjectType';
import { VideoService } from '../../service/video-service';
import { notificationContext } from '../context/NotificationContext';
import { ToastEnum } from '../../enum/ToastEnum';
import ProgressBarTool from '../progressBar/ProgressBar';

export interface Iprops {
  isModal: boolean;
  video: Video;
  partnerId: number;
  onSaveVideo: (video: Video) => void;
  closelModal: () => void;
}

const ModalSaveVideo = ({ video, partnerId, isModal, onSaveVideo, closelModal }: Iprops) => {
  const [isValidated, setIsValidated] = useState<boolean>(false);
  const [uploadPercentage, setUploadPercentage] = useState<number>(0);
  const videoService = useMemo(() => new VideoService(), []);
  const alert = useContext(notificationContext);

  //formulaire
  const [name, setName] = useState<string>(video.name);
  const [file, setFile] = useState<File>(null);

  function handleSubmit(event) {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      setIsValidated(true);
    } else {
      event.preventDefault();

      let saveVideo: Video = null;
      let extension;
      //update
      if (video.video_id != null) {
        if (file != null) {
          extension = file.name.split('.').pop();
        } else {
          extension = video.extension;
        }
        saveVideo = {
          video_id: video.video_id,
          name: name,
          dateCreation: video.dateCreation,
          dateModification: new Date(),
          extension: video.extension,
          user_id: video.user_id,
        };
        //creation
      } else {
        extension = file.name.split('.').pop();
        saveVideo = {
          video_id: null,
          name: name,
          dateCreation: new Date(),
          dateModification: null,
          extension: extension,
          user_id: partnerId,
        };
      }
      const saveVideoBlob = new Blob([JSON.stringify(saveVideo)], {
        type: 'application/json',
      });

      const formData = new FormData();
      formData.append('model', saveVideoBlob);
      formData.append('uploadFile', file);

      const option = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          if (percent < 100) {
            setUploadPercentage(percent);
          }
        },
      };

      videoService.saveVideo(formData, option).then((res) => {
        if (res.status === 200) {
          onSaveVideo(res.data);
          alert.onShowNotification(saveVideo.name + ' à bien été enregistré', ToastEnum.Success);
          setIsValidated(false);
          closelModal();
        } else {
          alert.onShowNotification(saveVideo.name + " ne s'est pas enregisté", ToastEnum.Alert);
          closelModal();
        }
      });
    }
  }

  let progressBar = uploadPercentage > 0 ? <ProgressBarTool uploadPercentage={uploadPercentage} /> : null;

  return (
    <Modal className="new-photo-modal" show={isModal} onHide={closelModal}>
      <Form onSubmit={(event) => handleSubmit(event)} noValidate validated={isValidated}>
        <Modal.Header>
          <Modal.Title>Sauvegarder la video</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputTextComponent controlId={'name'} label={'nom'} valueObject={name} onChangeValue={(value) => setName(value)} />
          <InputFile
            controlId={'file'}
            label={'fichier'}
            name={'file'}
            update={video.video_id != null}
            type={ObjectType.Video}
            onChangeFile={(file) => setFile(file)}
            isInvalid={null}
            isValid={null}
            error={null}
          />
          {progressBar}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={closelModal}>
            Annuler
          </Button>
          <Button variant="primary" type="submit">
            Valider
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalSaveVideo;
