import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/free-solid-svg-icons';
import './Footer.css';
import { Link } from 'react-router-dom';
import { userContext } from '../context/userContext';
import { VersionService } from '../../service/version-service';
const { version } = require('../../../package.json');


export interface IProps {}
interface Istate {
  apiVersion: String;
}

class Footer extends Component<IProps, Istate>  {

  VersionService = new VersionService();
  constructor(props: IProps) {
    super(props);
    this.state = {
      apiVersion: '',
    };
    this.VersionService = new VersionService();
  }

  componentDidMount() {
    this.VersionService.getApiVersion().then((res) => {
      const apiVersion: string = res.data;
      this.setState({ apiVersion: apiVersion});
    });
  }

  render() {
    return (
      <footer>
        <section>
          <article className="alias">
            <p>
              <FontAwesomeIcon icon={faCopyright} /> réalisé par
              <span> 'lm-dev'</span>
              </p>
            <p> api-version: <span>{this.state.apiVersion}</span></p>
            <p> client-version: <span>{version}</span></p>
          </article>
          <article>
            <h1>real-cream.fr</h1>
            <p>photos et vidéos à la demande.</p>
          </article>
          <article className="navigation">
            <nav>
              <Link to="/">accueil</Link>
              <Link to="/contact">contact</Link>
              <Link to="/gallery">galeries</Link>
              <userContext.Consumer>
                {({ isAdmin, handleLogout }) => {
                  if (isAdmin) {
                    return (
                      <span>
                        <Link to="/admin">admin</Link>
                        <Link onClick={handleLogout} to="/login">
                          déco
                        </Link>
                      </span>
                    );
                  } else {
                    return null;
                  }
                }}
              </userContext.Consumer>
            </nav>
          </article>
        </section>
      </footer>
    );
  }
}
export default Footer;
