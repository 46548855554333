import './Home.css';
import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import Fiche from './Fiche-aside';
import { PhotoService } from '../../service/photo-service';
import { Photo } from '../../model/photo-model';
import DisplayPhotoIndex from './Display-photo-Index';
import { Card } from 'react-bootstrap';

interface Iprops {}

interface Istate {
  photos: Photo[];
}

class Home extends Component<Iprops, Istate> {
  photoService: PhotoService;

  constructor(props: Iprops) {
    super(props);
    this.state = {
      photos: [],
    };
    this.photoService = new PhotoService();
  }

  componentDidMount() {
    this.photoService.getToIndexPhoto().then((res) => {
      const photos: Photo[] = res.data;
      this.setState({
        photos: photos,
      });
    });
  }

  render() {
    return (
      <main className="main-home">
        <section className="sectionBackGround">
          <p>De l'inspiration vers l'infini...</p>
        </section>
        <section className="aside">
          <Fiche />
				</section>
				<section className="sectionInfo">
          <article className="title"> 
            <Card>
              <Card.Body>
                <Card.Title>Production, Réalisation & Projet évenementiel<br /> 
                            association "DANCE-SYSTEM"
                </Card.Title>
                </Card.Body>
            </Card>
          </article>
          <article className="textIndex">
					<h4>Matérialisez les idées...</h4>
						<p>
            <FontAwesomeIcon icon={faQuoteLeft} className="fab" />
            R.C.A a pour finalité de fournir à la demande des photos et des vidéos. L'imagination et la création sont au coeur de nos
            productions. Ces deux atouts permettront de transformer concrètement un message, un souvenir ou une revendication en images. Dans un
            monde ou tout va trop vite,l'analyse et notre sérieux seront vos constantes.
            <FontAwesomeIcon icon={faQuoteRight} className="fab" />
            </p>
          </article>
          <article className="france" >
            <Card>
              <Card.Body><img src={process.env.PUBLIC_URL + '/staticImages/france.png'} alt="france"/></Card.Body>
            </Card>
          </article>
        </section>
        <section className="sectionPhotos">
          {this.state.photos.map((element) => {
            return <DisplayPhotoIndex key={element.photo_id} photo={element} />;
          })}
        </section>
      </main>
    );
  }
}

export default Home;
