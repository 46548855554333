import axios from 'axios';

export class UserService {
  private urlPrefix = process.env.REACT_APP_API_URL;

  getUserCurrent() {
    return axios.get(this.urlPrefix + '/usercurrent');
  }

  isAdmin() {
    return this.getUserCurrent().then((user) => {
      if (user.data) {
        return user.data.role.includes('ROLE_ADMIN') ? true : false;
      }
      return false;
    });
  }

  login(formData: FormData) {
    return axios.post(this.urlPrefix + '/login', formData);
  }

  logout() {
    return axios.get(this.urlPrefix + '/logout');
  }

  getUsers() {
    return axios.get(this.urlPrefix + '/users');
  }

  getParterns() {
    return axios.get(this.urlPrefix + '/partners');
  }

  createUser(formData: FormData, option: any) {
    return axios.post(this.urlPrefix + '/user', formData, option);
  }

  deleteUser(id: number) {
    return axios.delete(this.urlPrefix + `/user/${id}`);
  }
}
