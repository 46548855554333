import './modal-box.css';
import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Gallery } from '../../model/gallery-model';
import { GalleryService } from '../../service/gallery-service';
import { ToastEnum } from '../../enum/ToastEnum';
import { notificationContext } from '../context/NotificationContext';

interface IProps {
  isModal: boolean;
  gallery: Gallery;
  onUpdateListGallery: (gallery: Gallery) => void;
  onCloselModal: () => void;
}

interface IState {
  isValidated: boolean;
  isActiveModal: boolean;
  formNameGallery: string;
  formDescriptionGallery: string;
  messageToast: string;
}

class ModalBoxNewGallery extends Component<IProps, IState> {
  static contextType = notificationContext;
  galleryService: GalleryService;
  constructor(props: IProps) {
    super(props);
    this.state = {
      isValidated: false,
      isActiveModal: false,
      formNameGallery: this.props.gallery.name,
      formDescriptionGallery: this.props.gallery.description,
      messageToast: '',
    };
    this.galleryService = new GalleryService();
  }

  handleChangeGalleryName(event) {
    this.setState({ formNameGallery: event.target.value });
  }

  createGallery(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      this.setState({ isValidated: true });
    } else {
      event.preventDefault();
      this.setState({ isValidated: true });
      const gallery: Gallery = {
        gallery_id: this.props.gallery.gallery_id,
        name: this.state.formNameGallery,
        description: this.state.formDescriptionGallery,
        photos: this.props.gallery.photos,
      };
      this.galleryService.saveGallery(gallery).then((res) => {
        if (res.status === 200) {
          this.props.onUpdateListGallery(gallery);
          this.context.onShowNotification(gallery.name + ' à bien été enregistré', ToastEnum.Success);
          this.setState({ formNameGallery: '', isValidated: false });
          this.props.onCloselModal();
        } else {
          this.context.onShowNotification(gallery.name + " ne s'est pas enregisté", ToastEnum.Alert);
          this.props.onCloselModal();
        }
      });
    }
  }

  handleChangeDescription(event) {
    this.setState({ formDescriptionGallery: event.target.value });
  }

  render() {
    const { isModal, onCloselModal } = this.props;
    return (
      <article>
        <Modal show={isModal} onHide={onCloselModal} className="modal-new-gallery">
          <Form noValidate validated={this.state.isValidated} onSubmit={(event) => this.createGallery(event)} className="form-gallery">
            <Modal.Header>
              <Modal.Title>Créer une nouvelle galerie</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                <Form.Label>Nom : </Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={this.state.formNameGallery}
                  onChange={(event) => this.handleChangeGalleryName(event)}
                  maxLength={36}
                />
                <Form.Control.Feedback type="invalid">Veuillez renseinger une galerie</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="formDescription">
                <Form.Label>Description : </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={8}
                  required
                  name="formDescription"
                  value={this.state.formDescriptionGallery}
                  onChange={(event) => this.handleChangeDescription(event)}
                  maxLength={350}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={onCloselModal}>
                Annuler
              </Button>
              <Button variant="success" type="submit">
                Valider
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </article>
    );
  }
}

export default ModalBoxNewGallery;
