import { Component } from 'react';
import React from 'react';
import './gallery.css';
import { GalleryService } from '../../service/gallery-service';
import PhotosGallery from './Photos-gallery';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Form from 'react-bootstrap/Form';
import { Gallery } from './../../model/gallery-model';
import { Photo } from '../../model/photo-model';
import { userContext } from '../context/userContext';
import { LinkContainer } from 'react-router-bootstrap';
import { ToastEnum } from '../../enum/ToastEnum';
import { notificationContext } from '../context/NotificationContext';
import ModalInFo from '../crud-modal/Modal-Info';
import { deleteObject, saveObject } from '../../utils/utils';

export interface IProps {
  match: any;
}

export interface IState {
  gallery: Gallery;
  isModal: boolean;
}

class GalleryDetails extends Component<IProps, IState> {
  static contextType = notificationContext;
  GalleryService: GalleryService;
  constructor(props: IProps) {
    super(props);
    this.state = {
      gallery: {
        gallery_id: 0,
        name: '',
        description: '',
        photos: [],
      },
      isModal: false,
    };

    this.GalleryService = new GalleryService();
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.GalleryService.getGallery(parseInt(id)).then((res) => {
      this.setState({ gallery: res });
    });
  }

  handleCloseModalInfo() {
    this.setState({ isModal: false });
  }

  handleOpenModalInfo() {
    this.setState({ isModal: true });
  }

  handleChangeMainPhoto(event) {
    const photo_id = parseInt(event.target.value);
    let gallery = this.state.gallery;
    let mainPhoto: Photo;
    gallery.photos.forEach((photo) => {
      if (photo.photo_id === photo_id) {
        mainPhoto = photo;
        photo.mainPhoto = true;
      } else {
        photo.mainPhoto = false;
      }
    });
    this.setState({ gallery: gallery });
    this.GalleryService.saveGallery(gallery).then((res) => {
      if (res.status === 200) {
        this.context.onShowNotification(mainPhoto.nom + " est la nouvelle tête d'affiche", ToastEnum.Success);
      } else {
        this.context.onShowNotification("l'enregistrement a échoué", ToastEnum.Alert);
      }
    });
  }

  handleDeletePhoto(photo: Photo) {
    let gallery = this.state.gallery;
    gallery.photos = deleteObject(photo, gallery.photos);
    this.setState({ gallery: gallery });
  }

  handleUpdatePhoto(photo: Photo) {
    let gallery = this.state.gallery;
    // la photo a changé de gallerie, on doit la supprimer de cette galerie
    if (photo.gallery_id !== gallery.gallery_id) {
      gallery.photos = deleteObject(photo, gallery.photos);
      // juste une modification de l'image ou du nom, elle appartient toujours à la meme galerie.
    } else {
      gallery.photos = saveObject(photo, gallery.photos);
    }
    this.setState({ gallery: gallery });
  }

  render() {
    const ModalInfoGallery = this.state.isModal ? (
      <ModalInFo
        show={this.state.isModal}
        title={this.state.gallery.name}
        description={this.state.gallery.description}
        onCloseModalInfo={() => this.handleCloseModalInfo()}
      />
    ) : null;

    let value: number;
    let mainPhoto: Photo = this.state.gallery.photos.find((photo) => {
      return photo.mainPhoto === true || null;
    });

    if (mainPhoto) {
      value = mainPhoto.photo_id;
    } else {
      value = 0;
    }

    return (
      <main>
        <section>
          <section>
            <h1>{this.state.gallery.name}</h1>
            <LinkContainer to="/gallery">
              <Button variant="secondary">
                <FontAwesomeIcon icon={faArrowLeft} className="fontawsome-plus" /> retour aux galeries
              </Button>
            </LinkContainer>
            <Button variant="success" onClick={() => this.handleOpenModalInfo()}>
              <FontAwesomeIcon icon={faPlusCircle} className="fontawsome-plus" /> En savoir plus
            </Button>
            <userContext.Consumer>
              {({ isAdmin }) => {
                if (isAdmin) {
                  return (
                    <Form.Group controlId="galerie" className="select-main-photo">
                      <Form.Label>photo tête d'affiche</Form.Label>
                      <Form.Control required as="select" value={value} onChange={(event) => this.handleChangeMainPhoto(event)} custom>
                        <option value={0} disabled>
                          veuillez choisir une photo
                        </option>
                        {this.state.gallery.photos.map((photo) => {
                          return (
                            <option key={photo.photo_id} value={photo.photo_id}>
                              {photo.nom}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  );
                } else {
                  return null;
                }
              }}
            </userContext.Consumer>
          </section>
          {this.state.gallery.photos.map((photo) => (
            <PhotosGallery
              key={photo.photo_id}
              photo={photo}
              updatePhoto={(photo) => this.handleUpdatePhoto(photo)}
              deletePhoto={(photo) => this.handleDeletePhoto(photo)}
            />
          ))}
        </section>
        {ModalInfoGallery}
      </main>
    );
  }
}

export default GalleryDetails;
