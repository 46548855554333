import React, { useState } from 'react';
import { User } from './../../model/user-model';
import Card from 'react-bootstrap/Card';
import './partner.css';
import Image from 'react-bootstrap/Image';
import ModalParternsWorks from './ModalParternsWorks';
import ModalCreateUser from '../crud-modal/Modal-Save-User';
import ModalDeleteObject from '../crud-modal/Modal-delete-object';
import { ObjectType } from '../../enum/ObjectType';
import ModalInFo from '../crud-modal/Modal-Info';
import CrudButton from '../sharedComponent/CrudButton';

interface partnerProps {
  partner: User;
  onSaveUser: (user: User) => void;
  onDeletePartner: (partner: User) => void;
}

const Partner = ({ partner, onSaveUser, onDeletePartner }: partnerProps) => {
  const [modalWorkShow, setModalWorkShow] = React.useState<boolean>(false);
  const [modalSaveUser, setModalSaveUser] = React.useState<boolean>(false);
  const [modalDeletePartner, setModalDeletePartner] = React.useState<boolean>(false);
  const [isModalInfo, setIsModalInfo] = useState<boolean>(false);
  const [updateAvatarSource, setUpdatAvatarSource] = useState<string>(
    process.env.PUBLIC_URL + '/dynamicImages/avatar' + partner.user_id + '.' + partner.avatar
  );

  function handleSavePartner(user: User) {
    // use a useState attribut for force reload video because it's same key.... add a date who generate a unique key.
    setUpdatAvatarSource(process.env.PUBLIC_URL + '/dynamicImages/avatar' + partner.user_id + '.' + partner.avatar + '?' + new Date().getTime());
    onSaveUser(user);
  }

  const ModalSaveUser = modalSaveUser ? (
    <ModalCreateUser
      partner={partner}
      isModal={modalSaveUser}
      closeModal={() => setModalSaveUser(false)}
      onSaveUser={(user) => handleSavePartner(user)}
    />
  ) : null;

  const ModalDeletePartner = modalDeletePartner ? (
    <ModalDeleteObject
      id={partner.user_id}
      name={partner.firstname + ' ' + partner.lastname}
      objectType={ObjectType.User}
      isModalDelete={modalDeletePartner}
      onCloseModalDelete={() => setModalDeletePartner(false)}
      onDeleteObject={() => onDeletePartner(partner)}
    />
  ) : null;

  const ModalInfoContact = isModalInfo ? (
    <ModalInFo
      show={isModalInfo}
      title="prise de contact"
      description="Pour toute demande de contact, veuillez passer par real-cream.fr"
      onCloseModalInfo={() => setIsModalInfo(false)}
    />
  ) : null;

  return (
    <article className="partner-card">
      <Card>
        <Card.Body>
          <CrudButton onOpenModalSave={() => setModalSaveUser(true)} onOpenModalDelete={() => setModalDeletePartner(true)} />
          <Image src={updateAvatarSource} alt="avatar" roundedCircle />
          <Card.Title>{partner.firstname + ' ' + partner.lastname}</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">{partner.status}</Card.Subtitle>
          <Card.Text className="description-user">{partner.description}</Card.Text>
          <Card.Link href="#" onClick={() => setIsModalInfo(true)}>
            contacter
          </Card.Link>
          <Card.Link href="#" onClick={() => setModalWorkShow(true)}>
            mes travaux
          </Card.Link>
        </Card.Body>
      </Card>
      <ModalParternsWorks show={modalWorkShow} partner={partner} onCloseModal={() => setModalWorkShow(false)} />
      {ModalSaveUser}
      {ModalDeletePartner}
      {ModalInfoContact}
    </article>
  );
};

export default Partner;
