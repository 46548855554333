import React, { useEffect, useMemo, useState, Fragment, useContext } from 'react';
import { UserService } from './../../service/user-service';
import { User } from './../../model/user-model';
import Partner from './Partner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import ModalCreateUser from '../crud-modal/Modal-Save-User';
import { userContext } from '../context/userContext';
import { saveObject, deleteObject } from '../../utils/utils';
import SpinnerComponent from '../sharedComponent/SpinnerComponent';

const Partners = () => {
  const userservice = useMemo(() => new UserService(), []);
  const [partners, setPartners] = useState<User[]>([]);
  const [ismodalUser, setIsModalUser] = useState<boolean>(false);
  const currentUser = useContext(userContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    userservice.getParterns().then((res) => {
      setPartners(res.data);
      setIsLoading(false);
    });
  }, [userservice]);

  function handleSaveUser(user: User) {
    setPartners(saveObject(user, partners));
  }

  function handleDeletePartner(partner: User) {
    setPartners(deleteObject(partner, partners));
  }

  const ModalSaveUser = ismodalUser ? (
    <ModalCreateUser
      partner={{
        user_id: null,
        firstname: '',
        lastname: '',
        status: '',
        username: '',
        description: '',
        avatar: '',
        roles: [],
        photos: [],
        videos: [],
      }}
      isModal={ismodalUser}
      closeModal={() => setIsModalUser(false)}
      onSaveUser={(user) => handleSaveUser(user)}
    />
  ) : null;

  const partnersDisplay =
    partners.length > 0 ? (
      <Fragment>
        {partners.map((partner) => (
          <Partner
            key={partner.user_id}
            partner={partner}
            onSaveUser={(user) => handleSaveUser(user)}
            onDeletePartner={(partner) => handleDeletePartner(partner)}
          />
        ))}
      </Fragment>
    ) : (
      <p className="no-partner">
        <span>real-cream.fr</span> ne dispose pas encore de partenaire...
      </p>
    );

  const CrudButton = currentUser.isAdmin ? (
    <div>
      <Button variant="success" onClick={() => setIsModalUser(true)}>
        <FontAwesomeIcon icon={faPlusCircle} className="fontawsome-plus button-create-user" /> nouveau partenaire
      </Button>
    </div>
  ) : (
    <div></div>
  );

  if (isLoading) {
    return <SpinnerComponent />;
  } else {
    return (
      <main className="main-partners">
        <section>
          <h1>Les partenaires</h1>
          {CrudButton}
          {partnersDisplay}
          {ModalSaveUser}
        </section>
      </main>
    );
  }
};

export default Partners;
