import React, { Component } from 'react';
import Fiche from '../Home/Fiche-aside';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { ToastEnum } from '../../enum/ToastEnum';
import Card from 'react-bootstrap/Card';
import './contact.css';
import { Message } from '../../model/message-model';
import { MessageService } from '../../service/Message-service';
import { notificationContext } from '../context/NotificationContext';

interface IProps {}

interface IState {
  formLastName: string;
  formFirstName: string;
  formEmail: string;
  formMessage: string;
  isValidated: boolean;
  placeHolder: string;
  redError: string;
}

class Contact extends Component<IProps, IState> {
  static contextType = notificationContext;
  messageService: MessageService;
  constructor(props: IProps) {
    super(props);
    this.state = {
      formLastName: '',
      formFirstName: '',
      formEmail: '',
      formMessage: '',
      isValidated: false,
      placeHolder: '',
      redError: '',
    };

    this.messageService = new MessageService();
  }

  saveMessage(event) {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      this.setState({ isValidated: true, placeHolder: '* Champ Obligatoire', redError: 'red-error' });
    } else {
      event.preventDefault();
      let messageContact: Message = {
        nom: this.state.formLastName,
        prenom: this.state.formFirstName,
        email: this.state.formEmail,
        message: this.state.formMessage,
      };
      this.messageService.saveMessage(messageContact).then((res) => {
        if (res.status === 200) {
          this.context.onShowNotification("votre email a bien été envoyé", ToastEnum.Success);
        } else {
          this.context.onShowNotification("une erreur s'est produite, veuillez contacter l'admin.", ToastEnum.Alert);
        }
        this.setState({
          isValidated: false,
          formMessage: '',
          formEmail: '',
          formFirstName: '',
          formLastName: '',
        });
      });
    }
  }

  handleChangeInput(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    //@ts-ignore
    this.setState({ [name]: value });
  }

  resetForm() {
    this.setState({ formMessage: '', formEmail: '', formFirstName: '', formLastName: '', isValidated: false, placeHolder: '' });
  }

  render() {
    return (
      <main className="main-contact">
        <section className='aside'>
        <Fiche />
        </section>
        <section className="page-contact">
          <Card bg="light" text="dark" className="mb-2 form-card">
            <Card.Header>Formulaire de contact</Card.Header>
            <Card.Body>
              <Form onSubmit={(event) => this.saveMessage(event)} noValidate validated={this.state.isValidated}>
                <Form.Group controlId="formLastName">
                  <Form.Label>Nom : </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="formLastName"
                    value={this.state.formLastName}
                    onChange={(event) => this.handleChangeInput(event)}
                    maxLength={26}
                    placeholder={this.state.placeHolder}
                    className={this.state.redError}
                  />
                </Form.Group>
                <Form.Group controlId="formFirstName">
                  <Form.Label>Prénom : </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="formFirstName"
                    value={this.state.formFirstName}
                    onChange={(event) => this.handleChangeInput(event)}
                    placeholder={this.state.placeHolder}
                    className={this.state.redError}
                    maxLength={26}
                  />
                </Form.Group>
                <Form.Group controlId="formEmail">
                  <Form.Label>E-mail : </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="formEmail"
                    value={this.state.formEmail}
                    onChange={(event) => this.handleChangeInput(event)}
                    placeholder={this.state.placeHolder}
                    className={this.state.redError}
                    maxLength={75}
                  />
                </Form.Group>
                <Form.Group controlId="formMessage">
                  <Form.Label>Message : </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={8}
                    required
                    name="formMessage"
                    value={this.state.formMessage}
                    onChange={(event) => this.handleChangeInput(event)}
                    placeholder={this.state.placeHolder}
                    className={this.state.redError}
                    maxLength={350}
                  />
                </Form.Group>
                <Button variant="danger" onClick={() => this.resetForm()}>
                  Annuler
                </Button>
                <Button variant="success" type="submit">
                  Valider
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </section>
      </main>
    );
  }
}

export default Contact;
