import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faImage, faPhotoVideo, faVideo } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import Button from 'react-bootstrap/Button';
import { LinkContainer } from 'react-router-bootstrap';

class Fiche extends Component {
  render() {
    return (
      <aside className="actu">
        <article>
          <a href="https://www.facebook.com/pg/RealCreAm-421019322063310/posts/?ref=page_internal">
            <FontAwesomeIcon icon={faFacebookSquare} className="facebook-icon" />
          </a>
          <p>Alias: RealCreAm</p>
          <img src={process.env.PUBLIC_URL + '/staticImages/avatar.png'} alt="avatar" />
          <p>
            <FontAwesomeIcon icon={faEnvelope} /> production.realcream@gmail.com
          </p>
        </article>
        <article className="conception">
          <div>
            <p>
              <FontAwesomeIcon icon={faImage} className="far" />
              <span>Photo</span>
            </p>
          </div>
          <div>
            <p>
              <FontAwesomeIcon icon={faVideo} className="fas" />
              <span>video</span>
            </p>
          </div>
          <div>
            <p>
              <FontAwesomeIcon icon={faPhotoVideo} className="fab" />
              <span>Montage</span>
            </p>
          </div>
        </article>
        <LinkContainer to="/contact">
          <Button variant="light"> Me contacter</Button>
        </LinkContainer>
      </aside>
    );
  }
}

export default Fiche;
