import React, { Component } from 'react';
import './Admin.css';
import { Photo } from '../../model/photo-model';

interface Iprops {
  photo: Photo;
}

class Top3Photos extends Component<Iprops> {
  render() {
    const { photo } = this.props;
    let path: string = '';
    let id: number;
    if (photo.nom === 'defaultImage') {
      path = process.env.PUBLIC_URL + '/staticImages/no_photo.png';
    } else {
      path = process.env.PUBLIC_URL + '/dynamicImages/photoThumbnail' + photo.photo_id + '.' + photo.fileName;
    }
    return (
      <article key={id} className="display">
        <img src={path} alt="futurImgIndex" />
      </article>
    );
  }
}
export default Top3Photos;
