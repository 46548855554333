import React, { Component } from 'react';
import './Admin.css';
import { Photo } from '../../model/photo-model';
import Button from 'react-bootstrap/Button';
import Top3Photos from './Photo-details-top-index';
import { PhotoService } from '../../service/photo-service';
import { ToastEnum } from '../../enum/ToastEnum';
import { LinkContainer } from 'react-router-bootstrap';
import { notificationContext } from '../context/NotificationContext';

interface IProps {
  numberSelected: number;
  topPhotoIndex: Photo[];
  count: number;
  oldTopPhoto: Photo[];
  reset: () => void;
}

class AsideTopIndex extends Component<IProps> {
  static contextType = notificationContext;
  photoService: PhotoService;
  constructor(props: IProps) {
    super(props);
    this.photoService = new PhotoService();
  }

  updateToPhoto(event: any) {
    event.preventDefault();

    if (this.props.count === this.props.numberSelected) {
      let switchPhotos = [];
      switchPhotos = this.props.oldTopPhoto.concat(this.props.topPhotoIndex);
      this.photoService.setTop3Photo(switchPhotos).then((resp) => {
        this.context.onShowNotification("l'enregistrement s'est bien passé", ToastEnum.Success);
        this.props.reset();
      });
    } else {
      this.context.onShowNotification('Vous devez sélectionner ' + this.props.numberSelected + ' photos', ToastEnum.Warning);
    }
  }

  render() {
    const { numberSelected, topPhotoIndex, reset } = this.props;
    return (
      <aside className="aside-top-index">
        <p>Affiche {numberSelected} photos pour l'index</p>
        {topPhotoIndex.map((element) => {
          return <Top3Photos key={element.photo_id} photo={element} />;
        })}
        <div>
          <Button variant="danger" onClick={reset}>
            annuler
          </Button>
          <LinkContainer to="/home">
            <Button variant="success" onClick={(event) => this.updateToPhoto(event)}>
              valider
            </Button>
          </LinkContainer>
        </div>
      </aside>
    );
  }
}
export default AsideTopIndex;
