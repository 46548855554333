import { Photo } from '../model/photo-model';
import axios from 'axios';

export class PhotoService {
  private urlPrefix = process.env.REACT_APP_API_URL;

  getPhotos() {
    return axios.get(this.urlPrefix + '/photos');
  }

  getToIndexPhoto() {
    return axios.get(this.urlPrefix + '/phototopindex');
  }

  getPhoto(id: number) {
    return fetch(this.urlPrefix + `/photo/${id}`).then((res) => res.json());
  }

  setTop3Photo(photos: Photo[]) {
    return fetch(this.urlPrefix + '/updatetopthree', {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(photos),
    });
  }

  updatePhoto(photo: Photo) {
    return fetch(this.urlPrefix + '/photo', {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(photo),
    });
  }

  createPhoto(formData: FormData, option: any) {
    return axios.post(this.urlPrefix + '/photo', formData, option);
  }

  deletePhoto(id: number) {
    return fetch(this.urlPrefix + `/photo/${id}`, { method: 'DELETE' });
  }

  getImg(id: number) {
    return axios.get(this.urlPrefix + '/getPhoto?id=' + id);
  }
}
