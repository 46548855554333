import React, { useMemo, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ObjectType } from '../../enum/ObjectType';
import { VideoService } from '../../service/video-service';
import { ToastEnum } from '../../enum/ToastEnum';
import { notificationContext } from '../context/NotificationContext';
import { GalleryService } from '../../service/gallery-service';
import { PhotoService } from '../../service/photo-service';
import { UserService } from './../../service/user-service';

export interface IProps {
  id: number;
  name: string;
  objectType: ObjectType;
  isModalDelete: boolean;
  onDeleteObject: () => void;
  onCloseModalDelete: () => void;
}

const ModalDeleteObject = ({ id, name, objectType, isModalDelete, onCloseModalDelete, onDeleteObject }: IProps) => {
  const videoService = useMemo(() => new VideoService(), []);
  const galleryService = useMemo(() => new GalleryService(), []);
  const photoService = useMemo(() => new PhotoService(), []);
  const userService = useMemo(() => new UserService(), []);
  const contextNotif = useContext(notificationContext);
  function handleDeleteObject() {
    let promiseDelete;
    switch (objectType) {
      case 'USER':
        promiseDelete = userService.deleteUser(id);
        break;
      case 'PHOTO':
        promiseDelete = photoService.deletePhoto(id);
        break;
      case 'GALLERY':
        promiseDelete = galleryService.deleteGallery(id);
        break;
      case 'VIDEO':
        promiseDelete = videoService.deletevideo(id);
        break;
      default:
    }

    promiseDelete.then((res: Response) => {
      if (res.status === 200) {
        onCloseModalDelete();
        onDeleteObject();
        contextNotif.onShowNotification(name + ' à bien été supprimé', ToastEnum.Success);
      } else {
        onCloseModalDelete();
        contextNotif.onShowNotification(name + " n'as pas été supprimé correctement", ToastEnum.Alert);
      }
    });
  }

  return (
    <Modal show={isModalDelete} onHide={onCloseModalDelete} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Suppression</Modal.Title>
      </Modal.Header>
      <Modal.Body>Etes-vous sûr.e de bien vouloir supprimer "{name}" ?</Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={onCloseModalDelete}>
          Non
        </Button>
        <Button variant="success" onClick={() => handleDeleteObject()}>
          Oui
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDeleteObject;
