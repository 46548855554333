import React, { useState } from 'react';
import PaginationComponent from './PaginationComponent';
import Modal from 'react-bootstrap/Modal';
import { Photo } from '../../model/photo-model';
import ModalDeleteObject from '../crud-modal/Modal-delete-object';
import { ObjectType } from '../../enum/ObjectType';
import ModalSavePhoto from '../crud-modal/Modal-Save-Photo.';
import CrudButton from '../sharedComponent/CrudButton';

export interface PhotoWorkSpaceProps {
  partnerId: number;
  photos: Photo[];
  page: number;
  savePhoto: (photo: Photo) => void;
  onDeletePhoto: (photo: Photo) => void;
  onIncreasePage: (page: number, maxPage: number) => void;
  onDecreasePage: (page: number) => void;
  onSetPage: (page: number) => void;
}

const PhotoWorkSpace = ({ partnerId, photos, page, savePhoto, onDeletePhoto, onSetPage, onIncreasePage, onDecreasePage }: PhotoWorkSpaceProps) => {
  const maxPage: number = photos.length;
  const [isModal, isSetModal] = useState<boolean>(false);
  const [isModalDelete, isSetModalDelete] = useState<boolean>(false);

  const Pagination =
    maxPage > 1 ? (
      <PaginationComponent
        maxPage={maxPage}
        page={page}
        onSetPage={(page) => onSetPage(page)}
        onIncreasePage={() => onIncreasePage(page, maxPage)}
        onDecreasePage={() => onDecreasePage(page)}
      />
    ) : null;

  const modal = isModal ? (
    <ModalSavePhoto photo={photos[page - 1]} savePhoto={savePhoto} isModal={isModal} closelModal={() => isSetModal(false)} partnerId={partnerId} />
  ) : null;

  const modalDeleteObject = isModalDelete ? (
    <ModalDeleteObject
      id={photos[page - 1].photo_id}
      name={photos[page - 1].nom}
      objectType={ObjectType.Photo}
      isModalDelete={isModalDelete}
      onCloseModalDelete={() => isSetModalDelete(false)}
      onDeleteObject={() => onDeletePhoto(photos[page - 1])}
    />
  ) : null;

  let imgSrc = process.env.PUBLIC_URL + '/dynamicImages/photoThumbnail' + photos[page - 1].photo_id + '.' + photos[page - 1].fileName;

  return (
    <Modal.Body>
      <CrudButton onOpenModalSave={() => isSetModal(true)} onOpenModalDelete={() => isSetModalDelete(true)} />
      <Modal.Title>{photos[page - 1].nom}</Modal.Title>
      <img src={imgSrc} key={Date.now()} alt="partner-work" />
      {Pagination}
      {modalDeleteObject}
      {modal}
    </Modal.Body>
  );
};

export default PhotoWorkSpace;
