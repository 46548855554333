import axios from 'axios';

export class VideoService {
  private urlPrefix = process.env.REACT_APP_API_URL;

  getVideos() {
    return axios.get(this.urlPrefix + '/videos');
  }

  getvideo(id: number) {
    return fetch(this.urlPrefix + `/video/${id}`).then((res) => res.json());
  }

  saveVideo(formData: FormData, option: any) {
    return axios.post(this.urlPrefix + '/video', formData, option);
  }

  deletevideo(id: number) {
    return fetch(this.urlPrefix + `/video/${id}`, { method: 'DELETE' });
  }
}
