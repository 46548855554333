import React from 'react';
import { Link } from 'react-router-dom';
import './NotFoundPage.css';

interface Iprops {
  onShowNotification: any;
}

export default class NotFoundPage extends React.Component<Iprops> {
  render() {
    return (
      <main>
        <section className="not-found">
          <p>la page que vous essayez de charger n'existe pas.</p>
          <Link to="/">retour vers l'accueil</Link>
        </section>
      </main>
    );
  }
}
