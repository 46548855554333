import { createContext } from 'react';
import { ToastEnum } from '../../enum/ToastEnum';

interface Iprops {
  onShowNotification: (message: string, actionType: ToastEnum) => void;
}

export const notificationContext = createContext<Iprops>({
  onShowNotification: (message: string, actionType: ToastEnum) => {},
});
