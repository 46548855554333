import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import './modal-box.css';
import { Gallery } from '../../model/gallery-model';
import { Photo } from '../../model/photo-model';
import { GalleryService } from '../../service/gallery-service';
import { PhotoService } from '../../service/photo-service';
import { ToastEnum } from '../../enum/ToastEnum';
import ProgressBarTool from '../progressBar/ProgressBar';
import { notificationContext } from '../context/NotificationContext';
import { UserService } from '../../service/user-service';
import { UserConnected } from '../../model/userConnection';
import { VideoService } from '../../service/video-service';
import InputFile from '../inputForm/inputFile';
import { ObjectType } from '../../enum/ObjectType';

interface IProps {
  isModal: boolean;
  partnerId: number;
  photo: Photo;
  closelModal: () => void;
  savePhoto: (photo: Photo) => void;
}

interface IState {
  formName: string;
  galeries: Gallery[];
  idGalerie: number;
  isValidated: boolean;
  file: File;
  uploadPercentage: number;
  userId: number;
}

class ModalSavePhoto extends Component<IProps, IState> {
  static contextType = notificationContext;
  galleryService: GalleryService;
  PhotoService: PhotoService;
  videoService: VideoService;
  userService: UserService;
  constructor(props: IProps) {
    super(props);
    this.state = {
      formName: this.props.photo.nom,
      galeries: [],
      idGalerie: this.props.photo.gallery_id,
      isValidated: false,
      file: null,
      uploadPercentage: 0,
      userId: null,
    };
    this.galleryService = new GalleryService();
    this.PhotoService = new PhotoService();
    this.userService = new UserService();
    this.videoService = new VideoService();
  }

  componentDidMount() {
    Promise.all([this.galleryService.getGaleries(), this.userService.getUserCurrent()]).then((res) => {
      const galleries: Gallery[] = res[0].data;
      const user: UserConnected = res[1].data;
      if (this.state.idGalerie === 0 && galleries.length > 0) {
        this.setState({ idGalerie: galleries[0].gallery_id, galeries: galleries, userId: user.id });
      } else {
        this.setState({ galeries: galleries, userId: user.id });
      }
    });
  }

  handleChangeGalerie(event) {
    const idGallery: number = parseInt(event.target.value);
    this.setState({ idGalerie: idGallery });
  }

  createPhoto(event) {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      this.setState({ isValidated: true });
    } else {
      event.preventDefault();

      const file = this.state.file;
      let extension: string = '';
      let savePhoto = null;
      let galerieid: number;
      let userId: number;

      // attribution de l'auteur de la photo.
      if (this.props.partnerId) {
        userId = this.props.partnerId;
        galerieid = null;
      } else {
        userId = this.state.userId;
        galerieid = this.state.idGalerie;
      }
      //update
      if (this.props.photo.photo_id != null) {
        if (file != null) {
          extension = file.name.split('.').pop();
        } else {
          extension = this.props.photo.fileName;
        }
        savePhoto = {
          photo_id: this.props.photo.photo_id,
          nom: this.state.formName,
          dateCreation: new Date(),
          fileName: extension,
          topIndex: this.props.photo.topIndex,
          positionIndex: this.props.photo.positionIndex,
          mainPhoto: this.props.photo.mainPhoto,
          gallery_id: galerieid,
          user_id: userId,
        };

        //creaction
      } else {
        extension = file.name.split('.').pop();
        savePhoto = {
          photo_id: null,
          nom: this.state.formName,
          dateCreation: new Date(),
          fileName: extension,
          topIndex: false,
          positionIndex: null,
          mainPhoto: false,
          gallery_id: galerieid,
          user_id: userId,
        };
      }
      const fileBlob = new Blob([JSON.stringify(savePhoto)], {
        type: 'application/json',
      });

      const formData = new FormData();
      formData.append('model', fileBlob);
      formData.append('uploadFile', file);

      const option = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent;
          let percent = Math.floor((loaded * 100) / total);
          if (percent < 100) {
            this.setState({ uploadPercentage: percent });
          }
        },
      };

      this.PhotoService.createPhoto(formData, option).then((res) => {
        if (res.status === 200) {
          this.props.savePhoto(res.data);
          this.context.onShowNotification(savePhoto.nom + ' à bien été enregistré', ToastEnum.Success);
        } else {
          this.context.onShowNotification(savePhoto.nom + " ne s'est pas enregisté", ToastEnum.Alert);
        }
        this.props.closelModal();
      });
    }
  }

  render() {
    let progressBar = this.state.uploadPercentage > 0 ? <ProgressBarTool uploadPercentage={this.state.uploadPercentage} /> : null;
    const { partnerId, isModal, closelModal } = this.props;
    const SelectGallery = !partnerId ? (
      <Form.Group controlId="galerie">
        <Form.Label>Galerie : </Form.Label>
        <Form.Control required as="select" value={this.state.idGalerie} onChange={(event) => this.handleChangeGalerie(event)} custom>
          {this.state.galeries.map((galerie) => {
            return (
              <option key={galerie.gallery_id} value={galerie.gallery_id}>
                {galerie.name}
              </option>
            );
          })}
        </Form.Control>
      </Form.Group>
    ) : null;

    return (
      <article>
        <Modal className="new-photo-modal" show={isModal} onHide={closelModal}>
          <Form onSubmit={(event) => this.createPhoto(event)} noValidate validated={this.state.isValidated}>
            <Modal.Header>
              <Modal.Title>Sauvegarder votre photo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group controlId="name">
                <Form.Label>Nom : </Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={this.state.formName}
                  onChange={(event) => this.setState({ formName: event.target.value })}
                  maxLength={26}
                />
              </Form.Group>
              <InputFile
                controlId={'file'}
                label={'fichier'}
                name={'file'}
                update={this.props.photo.photo_id != null}
                type={ObjectType.Photo}
                onChangeFile={(file) => this.setState({ file: file })}
                isInvalid={null}
                isValid={null}
                error={null}
              />
              {SelectGallery}
              {progressBar}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={closelModal}>
                Annuler
              </Button>
              <Button variant="primary" type="submit">
                Valider
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </article>
    );
  }
}
export default ModalSavePhoto;
