import React, { Component } from 'react';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import './gallery.css';

import { LinkContainer } from 'react-router-bootstrap';

import { Photo } from '../../model/photo-model';
import { userContext } from '../context/userContext';
import ModalBoxNewGallery from '../crud-modal/Modal-Save-Gallery';
import ModalDeleteObject from '../crud-modal/Modal-delete-object';
import { ObjectType } from '../../enum/ObjectType';
import { Gallery } from './../../model/gallery-model';
import CrudButton from '../sharedComponent/CrudButton';

export interface Props {
  gallery: Gallery;
  onUpdateListGallery: (gallery: Gallery) => void;
  onDeleteListGallery: (gallery: Gallery) => void;
}

export interface State {
  isModalGallery: boolean;
  isModalDelete: boolean;
}

class GalleryOne extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isModalGallery: false,
      isModalDelete: false,
    };
  }
  render() {
    const { gallery, onUpdateListGallery, onDeleteListGallery } = this.props;

    const ModalGallerySave = this.state.isModalGallery ? (
      <ModalBoxNewGallery
        gallery={gallery}
        isModal={this.state.isModalGallery}
        onCloselModal={() => this.setState({ isModalGallery: false })}
        onUpdateListGallery={(gallery) => onUpdateListGallery(gallery)}
      />
    ) : null;

    const ModalGalleryDelete = this.state.isModalDelete ? (
      <ModalDeleteObject
        id={this.props.gallery.gallery_id}
        name={this.props.gallery.name}
        objectType={ObjectType.Gallery}
        isModalDelete={this.state.isModalDelete}
        onDeleteObject={() => onDeleteListGallery(this.props.gallery)}
        onCloseModalDelete={() => this.setState({ isModalDelete: false })}
      />
    ) : null;

    let path: string;
    let imgStyle: string;
    let mainPhoto: Photo = this.props.gallery.photos.find((photo) => {
      return photo.mainPhoto === true || null;
    });

    if (mainPhoto) {
      path = process.env.PUBLIC_URL + '/dynamicImages/photoThumbnail' + mainPhoto.photo_id + '.' + mainPhoto.fileName;
      imgStyle = '';
    } else {
      path = process.env.PUBLIC_URL + '/staticImages/no_photo.png';
      imgStyle = 'no-image';
    }

    return (
      <article className="gallery-article">
        <Card>
          <Card.Header>
            <userContext.Consumer>
              {({ isAdmin }) => {
                if (isAdmin) {
                  return (
                    <CrudButton
                      onOpenModalSave={() => this.setState({ isModalGallery: true })}
                      onOpenModalDelete={() => this.setState({ isModalDelete: true })}
                    />
                  );
                } else {
                  return null;
                }
              }}
            </userContext.Consumer>
            <Card.Title>{this.props.gallery.name}</Card.Title>
          </Card.Header>
          <Card.Img variant="top" src={path} className={imgStyle} />
          <Card.Body>
            <Card.Text>{this.props.gallery.description}</Card.Text>
          </Card.Body>
          <Card.Footer>
            <LinkContainer to={'/gallerydetails/' + this.props.gallery.gallery_id}>
              <Button variant="primary">Découvrir</Button>
            </LinkContainer>
          </Card.Footer>
        </Card>
        {ModalGallerySave}
        {ModalGalleryDelete}
      </article>
    );
  }
}

export default GalleryOne;
