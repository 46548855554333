/// external library
import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top';

//request url
import { UserService } from './service/user-service';
//context
import { userContext } from './component/context/userContext';

// personal component
import Header from './component/header/Header';
import Footer from './component/footer/Footer';
import Admin from './component/admin/Admin';
import Home from './component/Home/Home';
import Contact from './component/contact/Contact';
import Gallery from './component/gallery/Galleries';
import GalleryDetails from './component/gallery/Gallery-details';
import Login from './component/login/Login';
import PrivateRoute from './component/sharedComponent/PrivateRoute';
import NotFoundPage from './component/not-found-page/NotFoundPage';
import SpinnerComponent from './component/sharedComponent/SpinnerComponent';

import './Appstyle.css';
import ToastNotification from './component/toast/ToastNotification';
import { ToastEnum } from './enum/ToastEnum';
import { notificationContext } from './component/context/NotificationContext';
import Partners from './component/partner/Partners';
import { UserConnected } from './model/userConnection';
import ModalDeconnexion from './component/crud-modal/Modal-Deconnexion';

interface IProps {}
interface IState {
  isAdmin: boolean;
  currentUser: UserConnected;
  isLoading: boolean;
  isShowNotification: boolean;
  notificationMessage: string;
  actionType: ToastEnum;
  isModalDeconnexion: boolean;
}

class App extends Component<IProps, IState> {
  private userService: UserService;
  constructor(props: IProps) {
    super(props);
    this.userService = new UserService();
    this.state = {
      isAdmin: false,
      currentUser: null,
      isLoading: true,
      isShowNotification: false,
      notificationMessage: '',
      actionType: null,
      isModalDeconnexion: false,
    };
  }

  componentDidMount() {
    setInterval(() => {
      this.testSession();
    }, 100000);

    this.userService.getUserCurrent().then((value) => {
      let isAdmin = false;
      if (value.data) {
        isAdmin = value.data.role.includes('ROLE_ADMIN');
      }
      this.setState({ currentUser: value.data, isAdmin: isAdmin, isLoading: false });
    });
  }

  handleLogout = () => {
    this.userService.logout().then((res) => {
      this.setState({ isAdmin: false, currentUser: null });
    });
  };

  handleLogin() {
    this.userService.getUserCurrent().then((value) => {
      let isAdmin = false;
      if (value.data) {
        isAdmin = value.data.role.includes('ROLE_ADMIN');
      }
      this.setState({ currentUser: value.data, isAdmin: isAdmin, isLoading: false });
    });
  }

  handleShowNotification = (notificationMessage, actionType) => {
    this.setState({ isShowNotification: true, notificationMessage: notificationMessage, actionType: actionType });
  };

  closeNotification() {
    this.setState({ isShowNotification: false });
  }

  testSession() {
    this.userService.getUserCurrent().then((value) => {
      if (!value.data) {
        if (this.state.isAdmin) {
          this.setState({ isModalDeconnexion: true });
        }
        this.setState({ isAdmin: false });
      }
    });
  }

  render() {
    const Notification = this.state.isShowNotification ? (
      <ToastNotification
        messageToast={this.state.notificationMessage}
        actionType={this.state.actionType}
        isToast={this.state.isShowNotification}
        closeToast={() => this.closeNotification()}
      />
    ) : null;

    const ModalDeco = <ModalDeconnexion show={this.state.isModalDeconnexion} onCloseModalDeco={() => this.setState({ isModalDeconnexion: false })} />;

    if (this.state.isLoading) {
      return (
        <div>
          <Router>
            <Header />
            <main>
              <SpinnerComponent />
            </main>
            <Footer />
          </Router>
        </div>
      );
    } else {
      const userCurrent = { user: this.state.currentUser, isAdmin: this.state.isAdmin, handleLogout: this.handleLogout };
      const noficationContext = { onShowNotification: this.handleShowNotification };
      return (
        <div>
          <userContext.Provider value={userCurrent}>
            <notificationContext.Provider value={noficationContext}>
              <Router>
                <Header />
                <ScrollToTop>
                  <Switch>
                    <Route component={Home} path="/" exact />
                    <Route component={Contact} path="/contact" exact />
                    <Route component={Gallery} path="/gallery" exact />
                    <Route component={GalleryDetails} path="/gallerydetails/:id" exact />
                    <Route component={Partners} path="/partner" exact />
                    <PrivateRoute component={Admin} path="/admin" exact />
                    <Route component={() => <Login onLogin={() => this.handleLogin()} />} path="/login" exact />
                    <Route component={NotFoundPage} />
                  </Switch>
                </ScrollToTop>
                <Footer />
                {Notification}
                {ModalDeco}
              </Router>
            </notificationContext.Provider>
          </userContext.Provider>
        </div>
      );
    }
  }
}

export default App;
