import React, { useState, useContext, useMemo } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { User } from '../../model/user-model';
import { ToastEnum } from '../../enum/ToastEnum';
import { notificationContext } from '../context/NotificationContext';
import { UserService } from '../../service/user-service';
import InputFile from '../inputForm/inputFile';
import ProgressBarTool from '../progressBar/ProgressBar';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { ObjectType } from '../../enum/ObjectType';

interface ModalCreateUProps {
  partner: User;
  isModal: boolean;
  closeModal: () => void;
  onSaveUser: (user) => void;
}

const ModalCreateUser = ({ partner, isModal, closeModal, onSaveUser }: ModalCreateUProps) => {
  const contextNotif = useContext(notificationContext);
  const userservice = useMemo(() => new UserService(), []);
  const [uploadPercentage, setUploadPercentage] = useState<number>(0);

  function savePartner(form, actions) {
    let extension = '';
    if (partner.user_id) {
      extension = partner.avatar;
    } else {
      extension = form.file.name.split('.').pop();
    }

    const saveUser: User = {
      user_id: partner.user_id,
      firstname: form.firstName,
      lastname: form.lastName,
      status: form.status,
      description: form.description,
      username: form.userName,
      avatar: extension,
      roles: ['ROLE_PARTNER'],
      photos: partner.photos,
      videos: partner.videos,
    };
    const photoBlob = new Blob([JSON.stringify(saveUser)], {
      type: 'application/json',
    });

    const formData = new FormData();
    formData.append('user', photoBlob);
    formData.append('uploadFile', form.file);

    const option = {
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        let percent = Math.floor((loaded * 100) / total);
        if (percent < 100) {
          setUploadPercentage(percent);
        }
      },
    };

    userservice
      .createUser(formData, option)
      .then((res) => {
        const user: User = res.data;
        onSaveUser(user);
        closeModal();
        contextNotif.onShowNotification('le nouveau utilisateur a bien été enregistré', ToastEnum.Success);
      })
      .catch((error) => {
        if (error.response.status === 409) {
          actions.setStatus({ email: 'mail deja utilisé' });
        } else {
          closeModal();
          contextNotif.onShowNotification("un probleme est survenue lors de l'enregistrement", ToastEnum.Alert);
        }
      });
  }

  let progressBar = uploadPercentage > 0 ? <ProgressBarTool uploadPercentage={uploadPercentage} /> : null;

  const schema =
    partner.user_id == null
      ? yup.object({
          firstName: yup.string().required('Prénom obligatoire').max(13, 'taille max 13 caractères').min(3, 'taille min 3 caractères'),
          lastName: yup.string().required('Nom obligatoire').max(13, 'taille max 13 caractères').min(3, 'taille min 3 caractères'),
          userName: yup
            .string()
            .required('Email obligatoire')
            .email('invalid Email')
            .max(26, 'taille max 26 caractères')
            .min(3, 'taille min 3 caractères'),
          status: yup.string().required('Fonction obligatoire').max(26, 'taille max 26 caractères').min(3, 'taille min 3 caractères'),
          description: yup
            .string()
            .required('Description obligatoire')
            .max(160, 'taille max 160 caractères')
            .min(50, 'taille min 50 caractères')
            .test('maxLigne', 'ligne Max 10', (value) => {
              let number = 0;
              if (value) {
                for (let i = 0; i < value.length; i++) {
                  if (value[i] === '\n') number++;
                }
                return number < 7;
              }
            }),
          file: yup.string().required('Avatar obligatoire').nullable(),
        })
      : yup.object({
          firstName: yup.string().required('Prénom obligatoire').max(13, 'taille max 13 caractères').min(3, 'taille min 3 caractères'),
          lastName: yup.string().required('Nom obligatoire').max(13, 'taille max 13 caractères').min(3, 'taille min 3 caractères'),
          userName: yup
            .string()
            .required('Email obligatoire')
            .email('invalid Email')
            .max(26, 'taille max 26 caractères')
            .min(3, 'taille min 3 caractères'),
          status: yup.string().required('Fonction obligatoire').max(26, 'taille max 26 caractères').min(3, 'taille min 3 caractères'),
          description: yup
            .string()
            .required('Description obligatoire')
            .max(160, 'taille max 160 caractères')
            .min(50, 'taille min 50 caractères')
            .test('maxLigne', 'ligne Max 10', (value) => {
              let number = 0;
              if (value) {
                for (let i = 0; i < value.length; i++) {
                  if (value[i] === '\n') number++;
                }
                return number < 7;
              }
            }),
        });

  const formik = useFormik({
    initialValues: {
      firstName: partner.firstname,
      lastName: partner.lastname,
      userName: partner.username,
      status: partner.status,
      description: partner.description,
      file: null,
    },
    validationSchema: schema,
    onSubmit: (fields, actions) => {
      savePartner(fields, actions);
    },
  });

  return (
    <article>
      <Modal className="new-photo-modal" show={isModal} onHide={closeModal}>
        <Form noValidate onSubmit={formik.handleSubmit}>
          <Modal.Header>
            <Modal.Title>Sauvegarder votre partenaire</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="firstName">
              <Form.Label>Prénom : </Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={formik.values.firstName}
                onChange={formik.handleChange}
                isInvalid={formik.touched.firstName && !!formik.errors.firstName}
                isValid={formik.touched.firstName && !formik.errors.firstName}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.firstName}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="lastName">
              <Form.Label>Nom : </Form.Label>
              <Form.Control
                type="text"
                value={formik.values.lastName}
                name="lastName"
                onChange={formik.handleChange}
                isInvalid={formik.touched.lastName && !!formik.errors.lastName}
                isValid={formik.touched.lastName && !formik.errors.lastName}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.lastName}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="userName">
              <Form.Label>mail : </Form.Label>
              <Form.Control
                type="email"
                name="userName"
                value={formik.values.userName}
                onChange={formik.handleChange}
                isInvalid={(formik.touched.userName && !!formik.errors.userName) || (formik.status && formik.status.email)}
                isValid={formik.touched.userName && !formik.errors.userName}
              />
              {formik.status && formik.status.email ? (
                <Form.Control.Feedback type="invalid">cette adresse mail est deja utilisé</Form.Control.Feedback>
              ) : (
                <Form.Control.Feedback type="invalid">{formik.errors.userName}</Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group controlId="status">
              <Form.Label>Fonction : </Form.Label>
              <Form.Control
                type="text"
                value={formik.values.status}
                onChange={formik.handleChange}
                isInvalid={formik.touched.status && !!formik.errors.status}
                isValid={formik.touched.status && !formik.errors.status}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.status}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="description">
              <Form.Label>Description : </Form.Label>
              <Form.Control
                as="textarea"
                rows={8}
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                isInvalid={formik.touched.description && !!formik.errors.description}
                isValid={formik.touched.description && !formik.errors.description}
              />
              <Form.Control.Feedback type="invalid">{formik.errors.description}</Form.Control.Feedback>
            </Form.Group>
            <InputFile
              controlId={'file'}
              label={'avatar'}
              name="file"
              update={partner.user_id != null}
              type={ObjectType.Photo}
              onChangeFile={(file) => {
                formik.setFieldValue('file', file);
              }}
              isInvalid={formik.touched.file && !!formik.errors.file}
              isValid={formik.touched.file && !formik.errors.file}
              error={formik.errors.file}
            />
            {progressBar}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={closeModal}>
              Annuler
            </Button>
            <Button variant="primary" type="submit">
              Valider
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </article>
  );
};

export default ModalCreateUser;
