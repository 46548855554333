import { Route, Redirect } from 'react-router-dom';
import React from 'react';
import { userContext } from '../context/userContext';

const PrivateRoute = ({ component: Component, ...otherProps }) => {
  const { isAdmin } = React.useContext(userContext);
  return <Route {...otherProps} render={(props) => (isAdmin ? <Component {...props} /> : <Redirect to="/login" />)} />;
};

export default PrivateRoute;
