import { Component } from 'react';
import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export interface Iprops {
  show: boolean;
  description: string;
  title: string;
  onCloseModalInfo: () => void;
}

export interface ModalIngoGalleryState {}

class ModalInFo extends Component<Iprops, ModalIngoGalleryState> {
  constructor(props: Iprops) {
    super(props);
    this.state = {};
  }
  render() {
    const { show, title, description, onCloseModalInfo } = this.props;
    return (
      <Modal show={show} onHide={onCloseModalInfo} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{description}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onCloseModalInfo}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ModalInFo;
