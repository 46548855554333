import React from 'react';
import Form from 'react-bootstrap/Form';

export interface IProps {
  controlId: string;
  label: string;
  valueObject: string;
  onChangeValue: (value: string) => void;
}

const InputTextComponent = ({ controlId, label, valueObject, onChangeValue }: IProps) => {
  return (
    <Form.Group controlId={controlId}>
      <Form.Label>{label} : </Form.Label>
      <Form.Control required type="text" value={valueObject} onChange={(event) => onChangeValue(event.target.value)} maxLength={26} />
    </Form.Group>
  );
};

export default InputTextComponent;
