import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import './spinnerComponent.css';

const SpinnerComponent = () => {
  return (
    <div className="div-spinner">
      <p>Realcream.fr communique avec le serveur... </p>
      <Spinner animation="border" variant="danger" className=".spinner" />
    </div>
  );
};

export default SpinnerComponent;
