import React, { useState, Fragment } from 'react';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import { ObjectType } from '../../enum/ObjectType';
import { FormikErrors } from 'formik';

export interface Iprops {
  controlId: string;
  label: string;
  update: boolean;
  type: ObjectType;
  name: string;
  isInvalid: boolean;
  isValid: boolean;
  error: string | string[] | FormikErrors<any> | FormikErrors<any>[];
  onChangeFile: (file: File) => void;
}

const InputFile = ({ controlId, label, name, update, isInvalid, isValid, error, type, onChangeFile }: Iprops) => {
  const [nameFile, setNameFile] = useState<string>('aucun fichier selectionné');
  const [isShowInputFile, setIShowInputFile] = useState<boolean>(false);

  function handleChangeFile(event) {
    if (event.target.files.length) {
      setNameFile(event.target.files[0].name);
      onChangeFile(event.target.files[0]);
    } else {
      setNameFile('aucun fichier selectionné');
    }
  }

  let changeFile = null;
  let acceptFormat = type === ObjectType.Video ? 'video/*' : 'image/*';
  if (update) {
    changeFile = !isShowInputFile ? (
      <Badge variant="primary" onClick={() => setIShowInputFile(true)}>
        modifier le fichier
      </Badge>
    ) : (
      <Fragment>
        <Badge variant="danger" onClick={() => setIShowInputFile(false)}>
          annuler
        </Badge>
        <Form.Group controlId={controlId}>
          <Form.Label>{label} : </Form.Label>
          <Form.File
            required
            id="file"
            label={nameFile}
            data-browse="Browse"
            custom
            name={name}
            accept={acceptFormat}
            onChange={(event) => handleChangeFile(event)}
            isInvalid={isInvalid}
            isValid={isValid}
            feedback={error}
          />
        </Form.Group>
      </Fragment>
    );
  } else {
    changeFile = (
      <Form.Group controlId={controlId}>
        <Form.Label>{label} : </Form.Label>
        <Form.File
          required
          id="file"
          accept={acceptFormat}
          label={nameFile}
          data-browse="Browse"
          custom
          name="UploadFile"
          onChange={(event) => handleChangeFile(event)}
          isInvalid={isInvalid}
          isValid={isValid}
          feedback={error}
        />
      </Form.Group>
    );
  }

  return <Fragment>{changeFile}</Fragment>;
};

export default InputFile;
