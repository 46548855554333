import React, { useState, Fragment } from 'react';
import PaginationComponent from './PaginationComponent';
import Modal from 'react-bootstrap/Modal';
import ModalDeleteObject from '../crud-modal/Modal-delete-object';
import { ObjectType } from '../../enum/ObjectType';
import ModalSaveVideo from '../crud-modal/Modal-Save-Video';
import { Video } from '../../model/video-model';
import CrudButton from '../sharedComponent/CrudButton';

export interface IProps {
  partnerId: number;
  videos: Video[];
  page: number;
  onSaveVideo: (video: Video) => void;
  onDeleteVideo: (video: Video) => void;
  onIncreasePage: (page: number, maxPage: number) => void;
  onDecreasePage: (page: number) => void;
  onSetPage: (page: number) => void;
}

const VideoWorkSpace = ({ partnerId, videos, page, onDeleteVideo, onSaveVideo, onSetPage, onIncreasePage, onDecreasePage }: IProps) => {
  const maxPage: number = videos.length;
  const [isModalDelete, setIsModalDelete] = useState<boolean>(false);
  const [isModalSave, setIsModalSave] = useState<boolean>(false);
  const [updatevideoSource, setUpdatevideoSource] = useState<string>(
    process.env.PUBLIC_URL + '/dynamicImages/video' + videos[page - 1].video_id + '.' + videos[page - 1].extension
  );

  function handleSaveVideo(video: Video) {
    // use a useState attribut for force reload video because it's same key.... add a date who generate a unique key.
    setUpdatevideoSource(
      process.env.PUBLIC_URL + '/dynamicImages/video' + videos[page - 1].video_id + '.' + videos[page - 1].extension + '?' + new Date().getTime()
    );
    onSaveVideo(video);
  }

  let modalDeleteObject = isModalDelete ? (
    <ModalDeleteObject
      id={videos[page - 1].video_id}
      name={videos[page - 1].name}
      objectType={ObjectType.Video}
      isModalDelete={isModalDelete}
      onDeleteObject={() => onDeleteVideo(videos[page - 1])}
      onCloseModalDelete={() => setIsModalDelete(false)}
    />
  ) : null;

  const Pagination =
    maxPage > 1 ? (
      <PaginationComponent
        maxPage={maxPage}
        page={page}
        onSetPage={(page) => onSetPage(page)}
        onIncreasePage={() => onIncreasePage(page, maxPage)}
        onDecreasePage={() => onDecreasePage(page)}
      />
    ) : null;

  const ModalSave = isModalSave ? (
    <ModalSaveVideo
      video={videos[page - 1]}
      partnerId={partnerId}
      isModal={isModalSave}
      onSaveVideo={(video) => handleSaveVideo(video)}
      closelModal={() => setIsModalSave(false)}
    />
  ) : null;

  return (
    <Fragment>
      <Modal.Body className="modal-body-video">
        <CrudButton onOpenModalSave={() => setIsModalSave(true)} onOpenModalDelete={() => setIsModalDelete(true)} />
        <Modal.Title>{videos[page - 1].name}</Modal.Title>
        <video controls key={updatevideoSource}>
          <source src={updatevideoSource} type={'video/' + videos[page - 1].extension} />
        </video>
        {Pagination}
      </Modal.Body>
      {modalDeleteObject}
      {ModalSave}
    </Fragment>
  );
};

export default VideoWorkSpace;
