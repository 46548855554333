import { Gallery } from '../model/gallery-model';
import axios from 'axios';

export class GalleryService {
  private urlPrefix = process.env.REACT_APP_API_URL;

  getGaleries() {
    return axios.get(this.urlPrefix + '/galleries');
  }

  getGallery(id: number) {
    return fetch(this.urlPrefix + '/gallery/' + id).then((res) => res.json());
  }

  deleteGallery(id: number) {
    return axios.delete(this.urlPrefix + '/gallery/' + id);
  }

  saveGallery(gallery: Gallery) {
    return fetch(this.urlPrefix + '/savegallery', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(gallery),
    });
  }
}
