import React, { Component } from 'react';
import './ToastNotification.css';
import Toast from 'react-bootstrap/Toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationTriangle, faInfo, faRadiation, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { ToastEnum } from '../../enum/ToastEnum';

interface toast {
  title: string;
  fontAwesome: IconDefinition;
  className: string;
}

interface IProps {
  messageToast: string;
  actionType: ToastEnum;
  isToast: boolean;
  closeToast: () => void;
}

interface IState {
  show: boolean;
  message: string;
}

class ToastNotification extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      show: false,
      message: '',
    };
  }

  handleToast(actionType: ToastEnum) {
    let toast: toast;
    switch (actionType) {
      case 'SUCCESS':
        toast = {
          title: 'Success',
          fontAwesome: faCheck,
          className: 'success',
        };
        break;
      case 'WARNING':
        toast = {
          title: 'Warning',
          fontAwesome: faExclamationTriangle,
          className: 'warning',
        };
        break;
      case 'ALERT':
        toast = {
          title: 'Alerte',
          fontAwesome: faRadiation,
          className: 'alert',
        };
        break;
      case 'INFO':
        toast = {
          title: '  Info',
          fontAwesome: faInfo,
          className: 'info',
        };
        break;
    }
    return toast;
  }

  render() {
    const { messageToast, actionType, isToast, closeToast } = this.props;
    let toast: toast = this.handleToast(actionType);

    return (
      <div>
        <Toast style={{ position: 'fixed' }} className={toast.className} onClose={closeToast} show={isToast} delay={2500} autohide>
          <Toast.Header>
            <FontAwesomeIcon icon={toast.fontAwesome} />
            <strong className="mr-auto">{toast.title}</strong>
          </Toast.Header>
          <Toast.Body>{messageToast}</Toast.Body>
        </Toast>
      </div>
    );
  }
}
export default ToastNotification;
