import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import { UserService } from './../../service/user-service';
import { userContext } from '../context/userContext';

class Header extends Component {
  userService: UserService;
  constructor(props) {
    super(props);
    this.userService = new UserService();
  }

  render() {
    return (
      <header>
        <section className="marging-header">
          <section className="display-header-left">
            <article className="logo-title">
              <Link to="/">
                <img src={process.env.PUBLIC_URL + '/staticImages/logo_tailleReel.png'} alt="logo" />
              </Link>
              <h1>
                REAL<span>isation</span> CRE<span>ation</span> A.M
              </h1>
            </article>
          </section>
          <nav>
            <Link to="/">accueil</Link>
            <Link to="/contact">contact</Link>
            <Link to="/gallery">galeries</Link>
            <Link to="/partner">partenaires</Link>
            <userContext.Consumer>
              {({ isAdmin, handleLogout }) => {
                if (isAdmin) {
                  return (
                    <Fragment>
                      <Link to="/admin">admin</Link>
                      <Link onClick={handleLogout} to="/login">
                        déco
                      </Link>
                    </Fragment>
                  );
                } else {
                  return null;
                }
              }}
            </userContext.Consumer>
          </nav>
        </section>
      </header>
    );
  }
}
export default Header;
