import { Component } from 'react';
import React from 'react';
import Card from 'react-bootstrap/Card';
import { Photo } from '../../model/photo-model';
import { userContext } from '../context/userContext';
import ModalSavePhoto from '../crud-modal/Modal-Save-Photo.';
import ModalDeleteObject from '../crud-modal/Modal-delete-object';
import { ObjectType } from '../../enum/ObjectType';
import CrudButton from '../sharedComponent/CrudButton';

export interface IProps {
  photo: Photo;
  updatePhoto: (photo: Photo) => void;
  deletePhoto: (photo: Photo) => void;
}

export interface IState {
  isModalEdit: boolean;
  isModalDelete: boolean;
}

class PhotosGallery extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      isModalEdit: false,
      isModalDelete: false,
    };
  }

  render() {
    const { photo, updatePhoto, deletePhoto } = this.props;

    const modal = this.state.isModalEdit ? (
      <ModalSavePhoto
        photo={photo}
        savePhoto={updatePhoto}
        isModal={this.state.isModalEdit}
        closelModal={() => this.setState({ isModalEdit: false })}
        partnerId={null}
      />
    ) : null;

    const modalDeleteObject = this.state.isModalDelete ? (
      <ModalDeleteObject
        id={photo.photo_id}
        name={photo.nom}
        objectType={ObjectType.Photo}
        isModalDelete={this.state.isModalDelete}
        onDeleteObject={() => deletePhoto(photo)}
        onCloseModalDelete={() => this.setState({ isModalDelete: false })}
      />
    ) : null;

    return (
      <article key={photo.photo_id} className="photo-article">
        <Card border="secondary">
          <userContext.Consumer>
            {({ isAdmin }) => {
              if (isAdmin) {
                return (
                  <CrudButton
                    onOpenModalSave={() => this.setState({ isModalEdit: true })}
                    onOpenModalDelete={() => this.setState({ isModalDelete: true })}
                  />
                );
              } else {
                return null;
              }
            }}
          </userContext.Consumer>
          <Card.Title>
            <h3>{photo.nom}</h3>
          </Card.Title>
          <Card.Body>
            <Card.Img variant="top" src={process.env.PUBLIC_URL + '/dynamicImages/photoThumbnail' + photo.photo_id + '.' + photo.fileName} />
          </Card.Body>
        </Card>
        {modal}
        {modalDeleteObject}
      </article>
    );
  }
}

export default PhotosGallery;
