import Modal from 'react-bootstrap/Modal';
import React, { useState, useContext, Fragment } from 'react';
import { User } from '../../model/user-model';
import './partner.css';
import PhotoWorkSpace from './PhotoWorkSpace';
import VideoWorkSpace from './VideoWorkSpace';
import Button from 'react-bootstrap/Button';
import { Photo } from '../../model/photo-model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Video } from '../../model/video-model';
import { deleteObject, saveObject } from '../../utils/utils';
import ModalSaveVideo from '../crud-modal/Modal-Save-Video';
import ModalSavePhoto from './../crud-modal/Modal-Save-Photo.';
import { userContext } from '../context/userContext';

interface Iprops {
  show: boolean;
  partner: User;
  onCloseModal: () => void;
}

const ModalParternsWorks = ({ show, partner, onCloseModal }: Iprops) => {
  const [isShowVideos, setIsShowVideos] = useState<boolean>(partner.videos.length > 0);
  const [isShowPhotos, setIsShowPhotos] = useState<boolean>(partner.photos.length > 0);
  const [photos, setPhotos] = useState<Photo[]>(partner.photos);
  const [videos, setVideos] = useState<Video[]>(partner.videos);
  const [isModalSaveVideo, setIsModalSaveVideo] = useState<boolean>(false);
  const [isModalSavePhoto, setIsModalSavePhoto] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const currentUser = useContext(userContext);

  function handleDecrement(nbe) {
    if (page > 1) setPage(nbe - 1);
  }

  function handleIncrement(nbe, maxPage) {
    if (page < maxPage) setPage(nbe + 1);
  }

  function handleDisplayWork(type: string) {
    setPage(1);
    if (type === 'photo') {
      setIsShowPhotos(true);
      setIsShowVideos(false);
    } else {
      setIsShowPhotos(false);
      setIsShowVideos(true);
    }
  }

  function handleSaveVideo(video: Video) {
    setVideos(saveObject(video, videos));
    setPage(1);
    setIsShowPhotos(false);
    setIsShowVideos(true);
  }

  function handleSavePhoto(photo: Photo) {
    setPhotos(saveObject(photo, photos));
    setPage(1);
    setIsShowPhotos(true);
    setIsShowVideos(false);
  }

  function handleDeletePhoto(photo: Photo) {
    setPage(1);
    setPhotos(deleteObject(photo, photos));
    setIsModalSavePhoto(false);
    if (photos.length === 0) {
      setIsShowPhotos(false);
      if (videos.length > 0) {
        setIsShowVideos(true);
      }
    }
  }

  function handleDeleteVideo(video: Video) {
    setPage(1);
    setVideos(deleteObject(video, videos));

    if (videos.length === 0) {
      setIsShowVideos(false);
      if (photos.length > 0) {
        setIsShowPhotos(true);
      }
    }
  }

  let displayWork = null;
  let displayButton = null;
  if (isShowPhotos) {
    displayWork = (
      <PhotoWorkSpace
        partnerId={partner.user_id}
        photos={photos}
        page={page}
        savePhoto={(photo) => handleSavePhoto(photo)}
        onDeletePhoto={(photo) => handleDeletePhoto(photo)}
        onDecreasePage={(page) => handleDecrement(page)}
        onIncreasePage={(page, maxPage) => handleIncrement(page, maxPage)}
        onSetPage={(nbe) => setPage(nbe)}
      />
    );
  } else if (isShowVideos) {
    displayWork = (
      <VideoWorkSpace
        partnerId={partner.user_id}
        videos={videos}
        page={page}
        onSaveVideo={(video) => handleSaveVideo(video)}
        onDeleteVideo={(video) => handleDeleteVideo(video)}
        onDecreasePage={(page) => handleDecrement(page)}
        onIncreasePage={(page, maxPage) => handleIncrement(page, maxPage)}
        onSetPage={(nbe) => setPage(nbe)}
      />
    );
  } else {
    displayWork = <p className="no-work">les travaux sont en cours de realisation...</p>;
  }

  if (partner.videos.length > 0 && partner.photos.length > 0) {
    displayButton = (
      <div className="display-button-partner">
        <Button variant="secondary" onClick={() => handleDisplayWork('photo')}>
          Photo
        </Button>
        <Button variant="secondary" onClick={() => handleDisplayWork('video')}>
          Video
        </Button>
      </div>
    );
  }

  const modalFile = isModalSavePhoto ? (
    <ModalSavePhoto
      photo={{
        photo_id: null,
        nom: '',
        dateCreation: null,
        fileName: '',
        topIndex: false,
        positionIndex: null,
        mainPhoto: false,
        gallery_id: 0,
        user_id: null,
      }}
      savePhoto={(photo) => handleSavePhoto(photo)}
      isModal={isModalSavePhoto}
      closelModal={() => setIsModalSavePhoto(false)}
      partnerId={partner.user_id}
    />
  ) : null;

  const ModalSave = isModalSaveVideo ? (
    <ModalSaveVideo
      video={{
        video_id: null,
        name: '',
        dateCreation: null,
        dateModification: null,
        extension: '',
        user_id: partner.user_id,
      }}
      partnerId={partner.user_id}
      isModal={isModalSaveVideo}
      onSaveVideo={(video) => handleSaveVideo(video)}
      closelModal={() => setIsModalSaveVideo(false)}
    />
  ) : null;

  const CrudButton = currentUser.isAdmin ? (
    <Fragment>
      <Button variant="success" onClick={() => setIsModalSavePhoto(true)}>
        <FontAwesomeIcon icon={faPlusCircle} className="fontawsome-plus" /> {' photo'}
      </Button>
      <Button variant="success" onClick={() => setIsModalSaveVideo(true)}>
        <FontAwesomeIcon icon={faPlusCircle} className="fontawsome-plus" /> {' video'}
      </Button>
    </Fragment>
  ) : null;

  return (
    <div>
      <Modal show={show} onHide={onCloseModal}>
        <Modal.Header>
          {partner.lastname} {partner.firstname}
          {displayButton}
        </Modal.Header>
        {displayWork}
        <Modal.Footer>
          {CrudButton}
          <Button variant="secondary" onClick={onCloseModal}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
      {modalFile}
      {ModalSave}
    </div>
  );
};
export default ModalParternsWorks;
