import { Message } from '../model/message-model';

export class MessageService {
  private urlPrefix = process.env.REACT_APP_API_URL;
  saveMessage(messageContact: Message) {
    return fetch(this.urlPrefix + '/savemessage', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(messageContact),
    });
  }
}
