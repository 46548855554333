import React, { Component } from 'react';
import './Admin.css';
import { Photo } from '../../model/photo-model';
import { ActionPhoto } from '../../enum/ActionPhoto';
import ModalSavePhoto from '../crud-modal/Modal-Save-Photo.';
import ModalDeleteObject from '../crud-modal/Modal-delete-object';
import { ObjectType } from '../../enum/ObjectType';
import CrudButton from '../sharedComponent/CrudButton';

interface Props {
  count: number;
  photo: Photo;
  numberSelected: number;
  oncheckedPhoto: (event: React.ChangeEvent<HTMLInputElement>) => void;
  updatePhotoList: (photo: Photo) => void;
  deletePhotoList: (photo: Photo) => void;
}

interface Istate {
  isModal: boolean;
  photo: Photo;
  actionType: ActionPhoto;
  isModalDelete: boolean;
}

class PhotoDetails extends Component<Props, Istate> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isModal: false,
      isModalDelete: false,
      photo: this.props.photo,
      actionType: null,
    };

    this.deletePhoto = this.deletePhoto.bind(this);
    this.updatePhoto = this.updatePhoto.bind(this);
  }

  deletePhoto(photo: Photo) {
    this.props.deletePhotoList(photo);
  }

  updatePhoto(photo: Photo) {
    this.props.updatePhotoList(photo);
  }

  render() {
    const { photo, count, oncheckedPhoto, numberSelected } = this.props;
    let isDisabled;
    if (count >= numberSelected && !photo.topIndex) {
      isDisabled = 'disabled';
    } else {
      isDisabled = null;
    }

    const modal = this.state.isModal ? (
      <ModalSavePhoto
        photo={photo}
        savePhoto={this.updatePhoto}
        isModal={this.state.isModal}
        closelModal={() => this.setState({ isModal: false })}
        partnerId={null}
      />
    ) : null;

    const ModalDeletePhoto = this.state.isModalDelete ? (
      <ModalDeleteObject
        id={photo.photo_id}
        name={photo.nom}
        objectType={ObjectType.Photo}
        isModalDelete={this.state.isModalDelete}
        onCloseModalDelete={() => this.setState({ isModalDelete: false })}
        onDeleteObject={() => this.deletePhoto(photo)}
      />
    ) : null;

    let VideoSource = process.env.PUBLIC_URL + '/dynamicImages/photoThumbnail' + photo.photo_id + '.' + photo.fileName;
    return (
      <article key={photo.photo_id}>
        <h3 className="photo-name">{photo.nom} </h3>
        <input value={photo.photo_id} className="check3" name="checkPhoto" type="checkbox" disabled={isDisabled} onChange={oncheckedPhoto} />
        <div className="option-crud">
          <CrudButton onOpenModalSave={() => this.setState({ isModal: true })} onOpenModalDelete={() => this.setState({ isModalDelete: true })} />
        </div>
        <img key={Date.now()} src={VideoSource} alt="imageIndex" />
        {modal}
        {ModalDeletePhoto}
      </article>
    );
  }
}

export default PhotoDetails;
