export const deleteObject = (obj, array) => {
  array.splice(array.indexOf(obj), 1);
  return array;
};

export const saveObject = (obj, array) => {
  let objectArray = Object.values(obj);
  const index = array.findIndex((value) => {
    let valueArray = Object.values(value);
    return valueArray[0] === objectArray[0];
  });
  if (index === -1) {
    array.push(obj);
  } else {
    array[index] = obj;
  }

  return array;
};
