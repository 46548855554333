import React, { useContext, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { userContext } from './../context/userContext';
import './crudButton.css';

export interface IProps {
  onOpenModalSave: () => void;
  onOpenModalDelete: () => void;
}

const CrudButton = ({ onOpenModalSave, onOpenModalDelete }: IProps) => {
  const currentUser = useContext(userContext);

  const ButtonCrud = currentUser.isAdmin ? (
    <div className="button-crud">
      <FontAwesomeIcon icon={faEdit} className="font-awesome" onClick={onOpenModalSave} />
      <FontAwesomeIcon icon={faTrashAlt} className="font-awesome" onClick={onOpenModalDelete} />
    </div>
  ) : null;

  return <Fragment>{ButtonCrud}</Fragment>;
};

export default CrudButton;
