import React, { Component } from 'react';

//css
import './Admin.css';

//personnal component
import AsideTopIndex from './Aside-Top-index';
import PhotoDetails from './Photo-details';
import ModalBoxNewGallery from '../crud-modal/Modal-Save-Gallery';
import ModalSavePhoto from '../crud-modal/Modal-Save-Photo.';

//service
import { PhotoService } from '../../service/photo-service';
import { UserService } from '../../service/user-service';

//bootstrap component
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

//fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

//model
import { Photo } from '../../model/photo-model';

// context and utils
import { userContext } from '../context/userContext';
import { saveObject, deleteObject } from '../../utils/utils';

interface IProps {}

interface IState {
  numberSelected: number;
  count: number;
  photos: Photo[];
  topPhotoIndex: Photo[];
  idIncrementDefaultImg: number;
  isModalPhoto: boolean;
  isModalGallery: boolean;
  allChecked: false;
}

class AdminHome extends Component<IProps, IState> {
  photoService: PhotoService;
  userService: UserService;
  oldTopPhoto: Photo[];
  static contextType = userContext;

  constructor(props: IProps) {
    super(props);
    this.oldTopPhoto = [];
    this.photoService = new PhotoService();
    this.userService = new UserService();

    this.state = {
      count: 0,
      photos: [],
      topPhotoIndex: [],
      idIncrementDefaultImg: null,
      isModalPhoto: false,
      isModalGallery: false,
      numberSelected: 0,
      allChecked: false,
    };
  }
  componentDidMount() {
    this.photoService.getPhotos().then((res) => {
      let photos: Photo[] = res.data;
      // filtrer et enlever les photos des partners.
      photos = photos.filter((photo) => photo.gallery_id);

      this.oldTopPhoto = photos.filter((photo) => photo.topIndex);
      this.setState({
        photos: photos.reverse(),
        numberSelected: this.oldTopPhoto.length,
      });
      this.resetPhotoindex(this.oldTopPhoto.length);
    });
  }

  resetPhotoindex(photoNumber: number) {
    let inputs: any = document.querySelectorAll('.check3');
    inputs.forEach((input) => (input.checked = false));

    let topindexPhotoMirror = [];
    let i = 0;
    for (i; i < photoNumber; i++) {
      topindexPhotoMirror.push({
        photo_id: -[i + 1],
        nom: 'defaultImage',
      });
    }

    let photosMirror = [...this.state.photos];
    photosMirror.forEach((photo) => {
      photo.topIndex = false;
    });
    this.setState({ topPhotoIndex: topindexPhotoMirror, count: 0, idIncrementDefaultImg: -[i + 1], photos: photosMirror });
  }

  changePhotoIndex(event) {
    this.setState({ numberSelected: parseInt(event.target.value) });
    this.resetPhotoindex(event.target.value);
  }

  deletePhoto(photo: Photo) {
    let photos = [...this.state.photos];
    this.setState({ photos: deleteObject(photo, photos) });
  }

  savePhoto(photo: Photo) {
    let photos = [...this.state.photos];
    this.setState({ photos: saveObject(photo, photos) });
  }

  checkPhoto(event) {
    const target: any = event.target;
    const idPhoto: number = parseInt(event.target.value);
    let count: number = this.state.count;
    let photosMirror: Photo[] = [...this.state.photos];
    let topPhotoMirror: Photo[] = [...this.state.topPhotoIndex];
    const photo: Photo = photosMirror.find((photo) => idPhoto === photo.photo_id);
    const index: number = topPhotoMirror.findIndex((index) => index.photo_id < 0);
    let idIncrementDefaultImg: number = this.state.idIncrementDefaultImg;
    if (target.checked) {
      count++;
      photo.topIndex = true;
      topPhotoMirror.splice(index, 1, photo);
    } else {
      count--;
      idIncrementDefaultImg--;
      photo.topIndex = false;
      topPhotoMirror.splice(topPhotoMirror.indexOf(photo), 1, {
        photo_id: idIncrementDefaultImg,
        nom: 'defaultImage',
        dateCreation: null,
        fileName: null,
        topIndex: null,
        positionIndex: null,
        mainPhoto: false,
        gallery_id: null,
        user_id: null,
      });
    }

    this.setState({
      topPhotoIndex: topPhotoMirror,
      photos: photosMirror,
      count: count,
      idIncrementDefaultImg: idIncrementDefaultImg,
    });
  }

  render() {
    const modalGallery = this.state.isModalGallery ? (
      <ModalBoxNewGallery
        gallery={{ gallery_id: null, name: '', description: '', photos: [] }}
        isModal={this.state.isModalGallery}
        onCloselModal={() => this.setState({ isModalGallery: false })}
        onUpdateListGallery={() => null}
      />
    ) : null;

    const modalPhoto = this.state.isModalPhoto ? (
      <ModalSavePhoto
        photo={{
          photo_id: null,
          nom: '',
          dateCreation: null,
          fileName: '',
          topIndex: false,
          positionIndex: null,
          mainPhoto: false,
          gallery_id: 0,
          user_id: this.context.user.id,
        }}
        savePhoto={(photo) => this.savePhoto(photo)}
        isModal={this.state.isModalPhoto}
        closelModal={() => this.setState({ isModalPhoto: false })}
        partnerId={null}
      />
    ) : null;

    return (
      <main>
        <section>
          <Button variant="success" onClick={() => this.setState({ isModalPhoto: true })}>
            <FontAwesomeIcon icon={faPlusCircle} className="fontawsome-plus" /> nouvelle photo
          </Button>
          <Button variant="success" onClick={() => this.setState({ isModalGallery: true })}>
            <FontAwesomeIcon icon={faPlusCircle} className="fontawsome-plus" /> Nouvelle Galerie
          </Button>
          <Form.Group controlId="galerie" className="selected-nbe-photo">
            <Form.Label>photos index</Form.Label>
            <Form.Control required as="select" value={this.state.numberSelected} onChange={(event) => this.changePhotoIndex(event)} custom>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
            </Form.Control>
          </Form.Group>
          {modalGallery}
          {modalPhoto}
        </section>
        <section className="section-top-photo">
          <AsideTopIndex
            numberSelected={this.state.numberSelected}
            topPhotoIndex={this.state.topPhotoIndex}
            count={this.state.count}
            oldTopPhoto={this.oldTopPhoto}
            reset={() => this.resetPhotoindex(this.state.numberSelected)}
          />
          <section className="section-all-photo">
            {this.state.photos.map((photo) => (
              <PhotoDetails
                key={photo.photo_id}
                photo={photo}
                count={this.state.count}
                numberSelected={this.state.numberSelected}
                oncheckedPhoto={(event) => this.checkPhoto(event)}
                updatePhotoList={(photo) => this.savePhoto(photo)}
                deletePhotoList={(photo) => this.deletePhoto(photo)}
              />
            ))}
          </section>
        </section>
      </main>
    );
  }
}
export default AdminHome;
