import React, { Component } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { UserService } from '../../service/user-service';
import './login.css';
import { Redirect } from 'react-router-dom';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface LoginProps {
  onLogin: () => void;
}

export interface LoginState {
  username: string;
  password: string;
  isValidated: boolean;
  isAuth: boolean;
  errorVisible: string;
  IsVisiblePassWord: string;
}

class Login extends Component<LoginProps, LoginState> {
  userService: UserService;
  _mounted = false;
  constructor(props: LoginProps) {
    super(props);
    this.state = {
      username: '',
      password: '',
      isValidated: false,
      isAuth: false,
      errorVisible: 'no-visible',
      IsVisiblePassWord: 'password',
    };
    this.userService = new UserService();
  }

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  handleChangeInput(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    //@ts-ignore
    this.setState({ [name]: value });
  }

  saveAccount(event) {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      this.setState({ isValidated: true, errorVisible: 'no-visible' });
    } else {
      event.preventDefault();

      let formData = new FormData();
      formData.append('username', this.state.username);
      formData.append('password', this.state.password);

      this.userService
        .login(formData)
        .then((res) => {
          this.props.onLogin();
          this.resetForm();
          this.setState({ isAuth: true });
        })
        .catch((error) => {
          this.setState({ errorVisible: 'visible', password: '', username: '', isValidated: false });
        });
    }
  }

  resetForm() {
    this.setState({ password: '', username: '', isValidated: false });
  }

  render() {
    if (this.state.isAuth) {
      return <Redirect to="/" />;
    } else {
      return (
        <main>
          <Card bg="light" text="dark" className="form-card login-card">
            <Card.Header>
              <Card.Title>
                Bienvenue sur Real-cream
                <Card.Img className="img-login" variant="top" src={process.env.PUBLIC_URL + '/staticImages/logo_tailleReel.png'} />
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={(event) => this.saveAccount(event)} noValidate validated={this.state.isValidated}>
                <Form.Group controlId="username">
                  <Form.Label>E-mail : </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="username"
                    value={this.state.username}
                    onChange={(event) => this.handleChangeInput(event)}
                  />
                </Form.Group>
                <Form.Group controlId="password">
                  <Form.Label>Mot de passe : </Form.Label>
                  <Form.Control
                    className="input-password"
                    required
                    type={this.state.IsVisiblePassWord}
                    name="password"
                    value={this.state.password}
                    onChange={(event) => this.handleChangeInput(event)}
                  />
                  <FontAwesomeIcon
                    icon={faEye}
                    className="font-eye"
                    onMouseDown={() => this.setState({ IsVisiblePassWord: 'type' })}
                    onMouseUp={() => this.setState({ IsVisiblePassWord: 'password' })}
                  />
                </Form.Group>
                <p className={'error-message ' + this.state.errorVisible}>* Vos identifiants de connexions sont incorrects</p>
                <Button variant="danger" onClick={() => this.resetForm()}>
                  Annuler
                </Button>
                <Button variant="success" type="submit">
                  Valider
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </main>
      );
    }
  }
}

export default Login;
