import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

interface IProps {
  maxPage: number;
  page: number;
  onSetPage: (nbe: number) => void;
  onDecreasePage: () => void;
  onIncreasePage: () => void;
}

const PaginationComponent = ({ maxPage, page, onSetPage, onIncreasePage, onDecreasePage }: IProps) => {
  let items = [];
  for (let nbe = 1; nbe <= maxPage; nbe++) {
    items.push(
      <Pagination.Item key={nbe} onClick={() => onSetPage(nbe)} active={nbe === page}>
        {nbe}
      </Pagination.Item>
    );
  }

  return (
    <Pagination>
      <Pagination.Prev onClick={() => onDecreasePage()} />
      {items}
      <Pagination.Next onClick={() => onIncreasePage()} />
    </Pagination>
  );
};

export default PaginationComponent;
