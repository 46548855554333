import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Redirect } from 'react-router-dom';

export interface Props {
  show: boolean;
  onCloseModalDeco: () => void;
}

const ModalDeconnexion = ({ show, onCloseModalDeco }) => {
  const [isRedirect, setIsRedirect] = useState<boolean>(false);

  function handleRedirect() {
    setIsRedirect(true);
    onCloseModalDeco();
  }

  if (isRedirect) {
    return <Redirect to="/login" />;
  }
  return (
    <Modal show={show} onHide={onCloseModalDeco} animation={false}>
      <Modal.Header>
        <Modal.Title>Déconnecté ...</Modal.Title>
      </Modal.Header>
      <Modal.Body>Voulez-vous vous reconnecter ?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => handleRedirect()}>
          Oui
        </Button>
        <Button variant="secondary" onClick={onCloseModalDeco}>
          Non
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDeconnexion;
