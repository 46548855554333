import React, { Component } from 'react';
import { GalleryService } from '../../service/gallery-service';
import './gallery.css';
import { Gallery } from '../../model/gallery-model';
import GalleryOne from './Gallery-One';
import SpinnerComponent from '../sharedComponent/SpinnerComponent';

export interface Props {}

export interface State {
  galleries: Gallery[];
  isLoading: boolean;
}

class Galleries extends Component<Props, State> {
  GalleryService: GalleryService;
  constructor(props: Props) {
    super(props);
    this.state = {
      galleries: [],
      isLoading: true,
    };
    this.GalleryService = new GalleryService();
  }

  componentDidMount() {
    this.GalleryService.getGaleries().then((res) => {
      const galleries: Gallery[] = res.data;
      this.setState({ galleries: galleries, isLoading: false });
    });
  }

  handleUpdateList(gallery: Gallery) {
    const galleries = [...this.state.galleries];
    const index = galleries.findIndex((value) => value.gallery_id === gallery.gallery_id);
    galleries[index] = gallery;
    this.setState({ galleries: galleries });
  }

  handleDeleteList(gallery: Gallery) {
    let galleries = [...this.state.galleries];
    galleries.splice(galleries.indexOf(gallery), 1);
    this.setState({ galleries: galleries });
  }

  render() {
    if (this.state.isLoading) {
      return <SpinnerComponent />;
    } else {
      return (
        <main>
          <section>
            <h1>Les galeries</h1>
            {this.state.galleries.map((gallery) => (
              <GalleryOne
                key={gallery.gallery_id}
                gallery={gallery}
                onUpdateListGallery={(gallery) => this.handleUpdateList(gallery)}
                onDeleteListGallery={(gallery) => this.handleDeleteList(gallery)}
              />
            ))}
          </section>
        </main>
      );
    }
  }
}

export default Galleries;
