import React, { Component } from 'react';
import './ProgressBarTool.css';
import ProgressBar from 'react-bootstrap/ProgressBar';

interface IProps {
  uploadPercentage: number;
}
interface IState {}

class ProgressBarTool extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  render() {
    const uploadPercentage = this.props.uploadPercentage;
    const progressInstance = <ProgressBar now={uploadPercentage} label={`${uploadPercentage}%`} />;
    return <div>{progressInstance}</div>;
  }
}
export default ProgressBarTool;
