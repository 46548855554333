import { Component } from 'react';
import React from 'react';
import { Photo } from '../../model/photo-model';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { LinkContainer } from 'react-router-bootstrap';

export interface Props {
  photo: Photo;
}

export interface State {}

class DisplayPhotoIndex extends Component<Props, State> {
  render() {
    const { photo } = this.props;
    return (
      <article key={photo.photo_id} className="top-photo-index">
        <h3 className="top-photo-title">{photo.nom}</h3>
        <LinkContainer to={'/gallerydetails/' + photo.gallery_id}>
          <Button variant="light" className="button-more">
            <FontAwesomeIcon icon={faPlusCircle} className="circle-font" />
            More
          </Button>
        </LinkContainer>
        <img src={process.env.PUBLIC_URL + '/dynamicImages/photoThumbnail' + photo.photo_id + '.' + photo.fileName} alt="futurImgIndex" />
      </article>
    );
  }
}

export default DisplayPhotoIndex;
